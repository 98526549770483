import auth from '@/api/auth'
import utils from '@/utils/utils'
import router from '../../router/index'
import Vue from 'vue'

// 登录表单数据信息
const loginForm = {
  name: '',
  infoNo: '',

}

// 登录表单的校验规则
const loginFormRules = {
  name: [
    {
      required: true,
      message: '请输入账号',
      trigger: 'blur'
    },
  ],
  infoNo: [
    {
      required: true,
      message: '请输入身份证号',
      trigger: 'blur'
    },
    {
      min: 18,
      message: '身份证不能小于18位',
      trigger: 'blur'
    }
  ]
}


// 登录
const login = (formEl) => {
  utils.validFormAndInvoke(formEl, () => {
    auth.login(loginForm).then(resp => {
      if (resp.code === 200) {

        localStorage.setItem('authorization', resp.data)
        Vue.prototype.$notify({
          title: 'Tips',
          message: '登陆成功^_^',
          type: 'success',
          duration: 2000
        })
        router.push('/index')
      }

    }).catch(err => {
      console.log(err)
      //请求出错

        // 在页面上展示错误信息
        Vue.prototype.$notify({
          title: 'Tips',
          message: err.response.data.message,
          type: 'error',
          duration: 2000
        })


    })
  })
}

export default {
  loginForm,
  loginFormRules,

  login
}
