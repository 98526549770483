<template>
  <el-container>
    <el-main>
      <el-card class="box-card" shadow="always">
        <div slot="header" class="card-header">
          <p>报名小程序管理系统</p>
        </div>

        <div>
          <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm" :status-icon="true" label-width="100px">
            <el-form-item prop="name">
              <el-input prefix-icon="el-icon-user" v-model="loginForm.name" placeholder="姓名"></el-input>
            </el-form-item>

            <el-form-item prop="infoNo">
              <el-input prefix-icon="el-icon-user" v-model="loginForm.infoNo" placeholder="身份证号"></el-input>
            </el-form-item>



            <el-form-item>
              <el-button type="primary" @click="login($refs['loginForm'])" icon="el-icon el-icon-s-promotion">登录
              </el-button>

            </el-form-item>

          </el-form>
        </div>
      </el-card>
    </el-main>

    <Footer></Footer>
  </el-container>
</template>

<script>

import LoginFunc from '@/function-namespace/auth/LoginFunc'

import utils from '@/utils/utils'

export default {
  name: 'Login',

  data() {
    return {
      ...LoginFunc,
      captchaUrl: process.env.VUE_APP_CAPTCHA_URL
    }
  },
  created() {
    // 检验用户是否存在token,存在直接跳转主页
    utils.checkToken('/index')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/auth/login";

</style>
